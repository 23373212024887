<template>
  <base-view id="login-participant">
    <view-header
      title="Participant Login / Registration"
      image="bg-login-participant.jpg"
    >
      What kind of account do you have?
    </view-header>
    <view-section>
      <div
        class="flex flex-col justify-between overflow-hidden bg-neutral-light rounded-lg shadow-lg md:flex-row"
      >
        <div
          class="relative flex flex-col w-full p-4 pb-12 text-center text-white bg-primary"
        >
          <h1 class="mt-6 mb-2 text-4xl font-semibold">
            Health Benefits Login
          </h1>
          <!-- <p class="flex-1 m-0 mt-4">
            We administer various health benefits plans. Click here if you have
            an FSA, DCA, HRA, HSA, PKG, TRN, another form of benefit account. If
            you aren't sure what kind of account you have, this is most likely
            the place to go.
          </p> -->
          <p class="font-light text-xl">Which Debit Card Do You Have?</p>
          <div
            class="flex flex-col sm:flex-row sm:items-start w-full justify-between items-center"
          >
            <div
              class="w-full sm:w-1/2 flex flex-col justify-center items-center py-4"
            >
              <p class="font-bold mb-2"><em>White</em> Employee Card</p>
              <img class="h-36 sm:h-24 mb-2" src="@/assets/ElevateCard.png" />
              <Button
                class="m-2"
                reg
                light
                href="https://rmr.elevateaccounts.com/login"
              >
                <em>New</em> Employee Login
              </Button>
            </div>
            <div
              class="w-full sm:w-1/2 flex flex-col justify-center items-center py-4 "
            >
              <p class="font-bold mb-2"><em>Blue</em> Employee Card</p>
              <img class="h-36 sm:h-24 mb-2" src="@/assets/AlegeusCard.png" />
              <Button
                class="m-2"
                reg
                light
                href="https://rmrbenefits.wealthcareportal.com/Authentication/Handshake"
              >
                <em>Legacy</em> Employee Login
              </Button>
            </div>
          </div>
          <p class="font-bold italic text-sm">
            Click on the login link that looks like your current debit card. If
            you're not sure click the chat bubble at the bottom right.
          </p>
        </div>
        <div
          class="relative flex flex-col w-full p-4 pb-12 text-center bg-neutral-lighter"
        >
          <h1 class="my-6 text-4xl font-semibold">COBRA Login</h1>
          <p class="flex-1 m-0 mt-4 mb-4">
            COBRA is for those continuing their insurance/health benefits from
            their previous employer.
          </p>
          <div>
            <Button class="m-2" reg href="https://cobrapoint.benaissance.com/">
              COBRA Login
            </Button>
            <Button
              class="m-2"
              reg
              href="https://cobrapoint.benaissance.com/Registration/Identify"
            >
              COBRA Registration
            </Button>
          </div>
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    ViewSection,
    Button
  }
};
</script>
